<template>
	<div>
    Signing out...
  </div>
</template>	
<script type="text/javascript"></script>
<script>
  export default{

    data(){
      return{

      }
    },
    computed: {},
    filters: {},
    mounted() { 

          this.$cookie.set('usertoken', '');
          this.$cookie.set('organizationkey', '');

          //localStorage.setItem('message',JSON.stringify( {'command':'signoutall'} ));
          let self = this
          //localStorage.removeItem('message');
          this.$store.dispatch('doSignOut')
          .then(() =>{
           setTimeout(function() {
            location.href='/sign-in'
            //self.$router.push()
          },3000)
         })

        },


          methods: {
          }
        }
      </script>